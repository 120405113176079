import React from "react";
import { Container } from "react-bootstrap";
import CompanyMarquee from "../../components/Marquee/CompanyMarquee";
import { companyList } from "./companyListItems";
import { motion } from "framer-motion";
const Companies = () => {
  // Split the companyList dynamically into three groups
  const groupSize = Math.ceil(companyList.length / 3);
  const companyGroups = [
    companyList.slice(0, groupSize),
    companyList.slice(groupSize, groupSize * 2),
    companyList.slice(groupSize * 2),
  ];

  return (
    <div className="my-5">
      <header className="text-center my-5">
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Prepare for All Major Companies
        </motion.h2>
        <motion.p
          className="text-secondary"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Master Your Career Goals with Our Tailored, Company-Specific
          Preparation Courses!
        </motion.p>
      </header>
      <Container>
        {companyGroups.map((group, index) => (
          <CompanyMarquee
            key={index}
            companies={group}
            direction={index % 2 === 0 ? "right" : "left"}
            speed={index % 2 === 0 ? 30 : 50}
          />
        ))}
      </Container>
    </div>
  );
};

export default Companies;
