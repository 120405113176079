
// import picture5 from "../../assets/images/Interviewpics/picture5.png";
// import picture4 from "../../assets/images/Interviewpics/picture4.png";
// import picture3 from "../../assets/images/Interviewpics/picture3.png";
// import picture1 from "../../assets/images/Interviewpics/picture1.png";
// import picture2 from "../../assets/images/Interviewpics/picture2.png";
import picture6 from "../../assets/images/Interviewpics/interviewlogo.jpg";
import "./InterviewExpo.css";
//import { Download } from "lucide-react";
export const membersData = [
 
  {
    Company: "Accenture",
    role: "Educator",
    avatar: picture6,
    Download : false,
   
    route: "/accenture",
   
    },
  {
    Company: "TCS",
    role: "Industry Expert",
    avatar: picture6,
    Download : false,
   
   
   },
  {
    Company: "L&T",
    role: "Content Creator",
    avatar: picture6,
    Download : false,
     },
  {
    Company: "Cognizant",
    
    avatar: picture6,
    Download : true,

    },
    
    {
      Company: "Capgemini",
      
      avatar: picture6,
      Download : true,
  
      },
      
  
];
