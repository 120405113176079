import courseImg from "../../assets/images/courses.png"; 

export const courses = [
  {
    id: 1,
    title: "Basic Course for Beginners",
    category: "Python,HTML,CSS,Javascript,Mini Project",
    price: 500,
    rating: 4,
    duration: "40hr ",
    lessons: 45,
    sales: 0,
    image: courseImg,
    launched: true, // All courses are unlaunched
  },
  {
    id: 2,
    title: "Advanced Web Development",
    category: "Web Development",
    price: 4900,
    rating: 5,
    duration: "30hr 45min",
    lessons: 48,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 3,
    title: "Digital Marketing Fundamentals",
    category: "Marketing",
    price: 4700,
    rating: 4,
    duration: "18hr 15min",
    lessons: 28,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 4,
    title: "Python Programming Masterclass",
    category: "Programming",
    price: 5000,
    rating: 5,
    duration: "40hr 20min",
    lessons: 60,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 5,
    title: "Data Science with Python",
    category: "Data Science",
    price: 4900,
    rating: 4,
    duration: "50hr 10min",
    lessons: 45,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 6,
    title: "Graphic Design Essentials",
    category: "Graphic Design",
    price: 4600,
    rating: 4,
    duration: "25hr 50min",
    lessons: 32,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 7,
    title: "Machine Learning for Beginners",
    category: "Data Science",
    price: 4800,
    rating: 5,
    duration: "35hr 00min",
    lessons: 40,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 8,
    title: "React Native Mobile Development",
    category: "Mobile Development",
    price: 4950,
    rating: 5,
    duration: "28hr 10min",
    lessons: 38,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 9,
    title: "Introduction to Cloud Computing",
    category: "Cloud Computing",
    price: 4700,
    rating: 4,
    duration: "20hr 00min",
    lessons: 25,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 10,
    title: "Cybersecurity Basics",
    category: "Cybersecurity",
    price: 4650,
    rating: 4,
    duration: "15hr 30min",
    lessons: 20,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 11,
    title: "SEO Optimization Techniques",
    category: "Marketing",
    price: 4500,
    rating: 4,
    duration: "12hr 45min",
    lessons: 18,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 12,
    title: "JavaScript Fundamentals",
    category: "Programming",
    price: 4900,
    rating: 5,
    duration: "18hr 00min",
    lessons: 26,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 13,
    title: "Introduction to Blockchain",
    category: "Blockchain",
    price: 4950,
    rating: 4,
    duration: "27hr 15min",
    lessons: 30,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 14,
    title: "Photography Essentials",
    category: "Photography",
    price: 4600,
    rating: 4,
    duration: "15hr 50min",
    lessons: 22,
    sales: 0,
    image: courseImg,
    launched: false,
  },
  {
    id: 15,
    title: "Digital Illustration with Procreate",
    category: "Digital Art",
    price: 4800,
    rating: 5,
    duration: "22hr 10min",
    lessons: 35,
    sales: 0,
    image: courseImg,
    launched: false,
  },
];
