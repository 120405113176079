
const API_ENDPOINT = "https://devupvers-back-end.onrender.com/api/details"; 

const ContactService = {
  sendContactForm: async (formData) => {
    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send message");
      }

      return await response.json(); 
    } catch (error) {
      throw error;
    }
  },
};

export default ContactService;
