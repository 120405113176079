import React from "react";
import { Card, Image } from "react-bootstrap";
import Marquee from "react-fast-marquee";

const CompanyMarquee = ({ companies, direction = "right", speed = 30 }) => {
  return (
    <Marquee direction={direction} speed={speed} className="my-2">
      {companies.map((company, index) => (
        <Card key={index} className="h-100 shadow-sm mx-2">
          <Card.Body className="d-flex flex-row gap-2 align-items-center">
            <Image
              src={company.logo}
              alt={`${company.name} logo`}
              rounded
              width={40}
              height={40}
             style={{objectFit: "contain"}}
            />
            <div>
              <Card.Title className="text-start mb-0 h6">
                {company.name}
              </Card.Title>
              <Card.Subtitle className="text-muted small text-start">
                {company.industry}
              </Card.Subtitle>
            </div>
          </Card.Body>
        </Card>
      ))}
    </Marquee>
  );
};

export default CompanyMarquee;
