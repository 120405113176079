export const companyList = [
    { logo: "https://logo.clearbit.com/tcs.com", name: "TCS", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/wipro.com", name: "Wipro", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/capgemini.com", name: "Capgemini", industry: "Consulting" },
    { logo: "https://logo.clearbit.com/infosys.com", name: "Infosys", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/techmahindra.com", name: "Tech Mahindra", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/hcltech.com", name: "HCL Technologies", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/accenture.com", name: "Accenture", industry: "Consulting" },
    { logo: "https://logo.clearbit.com/ibm.com", name: "IBM", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/deloitte.com", name: "Deloitte", industry: "Consulting" },
    { logo: "https://logo.clearbit.com/kpmg.com", name: "KPMG", industry: "Consulting" },
    { logo: "https://logo.clearbit.com/ey.com", name: "EY", industry: "Consulting" },
    { logo: "https://logo.clearbit.com/pwc.com", name: "PwC", industry: "Consulting" },
    { logo: "https://logo.clearbit.com/cognizant.com", name: "Cognizant", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/mindtree.com", name: "Mindtree", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/hexaware.com", name: "Hexaware", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/lntinfotech.com", name: "L&T Infotech", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/genpact.com", name: "Genpact", industry: "Business Services" },
    { logo: "https://logo.clearbit.com/slb.com", name: "Schlumberger", industry: "Engineering" },
    { logo: "https://logo.clearbit.com/accenture.com", name: "Accenture", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/mphasis.com", name: "Mphasis", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/oracle.com", name: "Oracle", industry: "Technology" },
    { logo: "https://logo.clearbit.com/sap.com", name: "SAP", industry: "Technology" },
    { logo: "https://logo.clearbit.com/zoho.com", name: "Zoho", industry: "Technology" },
    { logo: "https://logo.clearbit.com/salesforce.com", name: "Salesforce", industry: "CRM Software" },
    { logo: "https://logo.clearbit.com/adobe.com", name: "Adobe", industry: "Software" },
    { logo: "https://logo.clearbit.com/microsoft.com", name: "Microsoft", industry: "Technology" },
    { logo: "https://logo.clearbit.com/google.com", name: "Google", industry: "Technology" },
    { logo: "https://logo.clearbit.com/aws.amazon.com", name: "AWS", industry: "Cloud Computing" },
    { logo: "https://logo.clearbit.com/dxc.com", name: "DXC Technology", industry: "IT Services" },
    { logo: "https://logo.clearbit.com/virtusa.com", name: "Virtusa", industry: "IT Services" },
  ];
  